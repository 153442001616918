import React from 'react'

export default function IconPin({className}) {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M6.875 0.25C5.0523 0.252171 3.30488 0.977196 2.01604 2.26604C0.727196 3.55488 0.00217093 5.3023 0 7.125C0 13.0076 6.25 17.4509 6.51609 17.6371C6.62134 17.7106 6.74662 17.75 6.875 17.75C7.00338 17.75 7.12866 17.7106 7.23391 17.6371C7.5 17.4509 13.75 13.0076 13.75 7.125C13.7478 5.3023 13.0228 3.55488 11.734 2.26604C10.4451 0.977196 8.6977 0.252171 6.875 0.25ZM6.87547 4.62523C7.36992 4.62523 7.85327 4.77186 8.26439 5.04656C8.67552 5.32126 8.99595 5.71171 9.18517 6.16853C9.37439 6.62534 9.42389 7.12801 9.32743 7.61296C9.23097 8.09791 8.99287 8.54337 8.64324 8.893C8.2936 9.24263 7.84815 9.48073 7.36319 9.5772C6.87824 9.67366 6.37558 9.62415 5.91876 9.43493C5.46195 9.24571 5.0715 8.92528 4.79679 8.51416C4.52209 8.10304 4.37547 7.61969 4.37547 7.12523C4.37547 6.46219 4.63886 5.82631 5.1077 5.35747C5.57654 4.88863 6.21243 4.62523 6.87547 4.62523Z" fill="currentColor"/>
    </svg>

  )
}
